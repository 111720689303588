//import basic react
import React from 'react';

//import Loading css 
import './Loading.css';

export default function Loading() {
  return (
    <div className="loading-page">
        <div className="loading">
            <div></div>
            <div></div>
        </div>
    </div>
  );
}
