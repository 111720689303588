//import Basic
import React from "react";

import FavoriteBorder from '@mui/icons-material/FavoriteBorder';

//import Css
import "./NotFound.css";

//export Not Found Component
export default function NotFound() {
 
  return (
    <div className="not-found">
      <main>
        <h1>
          4
          <span>
            0
          </span>
          4
        </h1>
        <h2>Page not found</h2>
      </main>
    </div>
  );
}
