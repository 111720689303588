import React, { useState, useEffect } from 'react';

import ImageGallery from 'react-image-gallery';

import "./Gallery.css";


export default function MyGallery(props) {
  const [images, setImages] = useState([]);
  const [gallery, setGallery] = useState(props.gallery);

  useEffect(() => {
    setImages(props.gallery);
  }, [props]);

  useEffect(() => {
    let galleryItems = [];
    images.map((image)=>{
      galleryItems.push({
        original:image.image
      })
    });
    setGallery(galleryItems);
  }, [images,props]);

  return <ImageGallery items={gallery} autoPlay={true}  showBullets={true}  showFullscreenButton={false} showThumbnails={false} showPlayButton={false}/>;
}