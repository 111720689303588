//import basic react
import React, { useState, useEffect } from 'react';

//import api
import api from '../../api/Method';

//import About css 
import './Footer.css';

//import images
import Logo from "../../assets/imgs/footer/logo.png";
import Email from '../../assets/imgs/footer/email.png';
import Phone from '../../assets/imgs/footer/mobile.png';
import Mobile from '../../assets/imgs/footer/call.png';
import Location from '../../assets/imgs/footer/location.png';

//import components from react-bootstrap
import {Container, Row, Col } from 'react-bootstrap';

export default function Footer() {
    const [categories, setCategories] = useState([]);
    const [contact, setContact] = useState({});
    const [url, setUrl] = useState("https://dawaa-e.com/ubaha/api/category");
    const [urlContact, setUrlContact] = useState("https://dawaa-e.com/ubaha/api/contact_us_info");
    
    useEffect(()=>{
        //set url
        setUrl("https://dawaa-e.com/ubaha/api/category");
        setUrlContact("https://dawaa-e.com/ubaha/api/contact_us_info");
    },[]);
  
    useEffect(()=>{
        //get hot categories data from database
        api.get(url)
        .then(
            (response)=> {
                if(response.data.result=="success") {         
                   setCategories(response.data.data);
                }
            }
        )
        .catch((error)=>{})
     },[]);

    useEffect(()=>{
    //get contact data from database
    api.get(urlContact)
    .then(
        (response)=> {
            if(response.data.result=="success") {
                setContact(response.data.data);
            }
        }
    )
    .catch((error)=>{})
    },[]);
 
    const categoryItems = () => {
        let items = [];
        if(categories.length>0) {
            categories.map((item,index)=>{
                if(item!=null && item!=undefined) {
                    items.push(
                        <Col className="justify-content-between flex-wrap text-center" xs="6" sm="6" key={index}>
                            <p>{item.name}</p>
                        </Col>
                    )
                }
            });
            return items;
        }
    }

    const contactItems = () => {
        if(contact!=null) {
            return (
                <div className="address">
                    <div className="address-item">
                        <img src={Phone} width="24px" height="24px" alt="mobile-number" />
                        <a href={`tel:${contact.mobile}`}>{contact.mobile?contact.mobile:"mobile number..."}</a>
                    </div>
                    <div className="address-item">
                        <img src={Mobile} width="24px" height="24px" alt="phone-number" />
                        <a href={`tel:011${contact.phone}`}>{contact.phone?contact.phone:"phone number..."}</a>
                    </div>
                    <div className="address-item">
                        <img src={Email} width="24px" height="24px" alt="email" />
                        <a href={`mailto:${contact.email}`}>{contact.email?contact.email:"email@gmail.com"}</a>
                    </div>
                    <div className="address-item">
                        <img src={Location} width="24px" height="24px" alt="location" />
                        <a href="https://goo.gl/maps/RPyy6MUaTjehEhG37">{contact.address?contact.address:"address"}</a>
                    </div>
              </div>
            );
        }
    }

  return (
    <div className="footer">
        <Container>
            <Row className=" justify-content-center">
                <Col md="3">
                    <div className="footer-logo">
                        <img src={Logo} width="105" height="61" alt="ubbaha"/>
                    </div>
                </Col>
                <Col md="5" >
                    <div className="footer-hot-categories">
                        <p className="footer-section-header">Hot categories</p>
                        <Row className="justify-content-between text-center footer-hot-categories-items">
                            {categories.length>0?categoryItems():""}            
                        </Row>                       
                    </div>
                </Col>
                <Col md="4" className=" d-md-flex justify-content-center">
                    <div className="footer-contact-us">
                        <p className="footer-section-header footer-section-header-contact">Contact us</p>
                        <Row>
                            {contact?contactItems():""}
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  );
}
