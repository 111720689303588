//import Basic
import React, { useState, useEffect } from "react";

//import api
import api from "../../api/Method";

//import sweet alert
import Swal from 'sweetalert2';

//import component from react-bootstrap
import { Container, Row, Col } from "react-bootstrap";

import { Form, Button } from "react-bootstrap";

//import Css
import "./Login.css";

//import image
import Icon from '../../assets/imgs/login/login.svg';
import Mobile from '../../assets/imgs/login/mobile.png';
import Password from '../../assets/imgs/login/password.png';

//import icon
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

//export Login Component
export default function Login() {
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [url, setUrl] = useState("https://dawaa-e.com/ubaha/api/login");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    //set url
    setUrl("https://dawaa-e.com/ubaha/api/login");
  }, []);

  const submitData = (e) => {
    e.preventDefault();
    const data = {
      mobile,
      password,
    };

    api
      .post(url, data)
      .then((response) => {
        if (response.data != null && response.data.result == "success") {
          sessionStorage.setItem("token", response.data.data.token);
          Swal.fire({
            icon: '',
            text: response.data.result,
            showConfirmButton: false,
            timer: 2000
          }).then(() => {
            window.location.href = "/home";
          })
        } else {
          Swal.fire({
            icon: '',
            text: response.data.error_description,
            showConfirmButton: false,
            timer: 2000
          })
        }
      })
      .catch((error) => {});
  };

  const forgotPassword = () => {
    window.location.href = "/forgot-password";
  };

  const register = () => {
    window.location.href = "/register";
  }

   if(sessionStorage.getItem("token")!=null) {
      window.location.href="/home";
      return "";
    }

  return (
    <div className="login">
      <Container fluid>

        <Row className="justify-content-center align-items-center">
          <Col md="7" xl="5">
            <Form onSubmit={submitData}>
              <Form.Group className="login-header">
                <h3>Login</h3>
                <p>Please enter your mobile number and password:</p>  
              </Form.Group>
              <Form.Group className="login-mobile">
                <img src={Mobile} width="36" height="36" alt="mobile-number" />
                <Form.Control
                  type="text"
                  required
                  minLength="10"
                  maxLength="10"
                  name="mobile"
                  placeholder="Mobile number"
                  onChange={(e) => setMobile(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="login-password">
                <img src={Password} width="36" height="36" alt="mobile-number" />
                <Form.Control
                  type={showPassword?"text":"password"}
                  required
                  minLength="8"
                  name="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                {
                  showPassword?
                  <VisibilityOffIcon className="password-icon" onClick={()=>setShowPassword(!showPassword)} />
                  :
                  <VisibilityIcon className="password-icon" onClick={()=>setShowPassword(!showPassword)} />
                }
              </Form.Group>

              <Form.Group>
                <span onClick={forgotPassword}>
                  <p>forgot password?</p>
                </span>
              </Form.Group>

              <Form.Group className="form-button">
                <Button className="d-flex align-items-center justify-content-center w-100" type="submit">
                  <img src={Icon} width="24px" height="24px" className="login-icon" alt="login" />
                  Login
                </Button>
              </Form.Group>

              <Form.Group className="create-account">
                <p className="append-text d-inline-block">Don't have an account? </p>
                <span onClick={register}>
                  <p className="d-inline-block ml-1"> Create one</p>
                </span>
              </Form.Group>

            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
