//import basic react
import React, { useState, useEffect } from "react";

//import api
import api from "../../api/Method";

//import About css
import "./About.css";

//import components from react-bootstrap
import { Container, Row, Col } from "react-bootstrap";

//import logo
import Logo from "../../assets/imgs/about/logo.png";

//import loading
import Loading from "../loading/Loading";

export default function About() {
  const [about, setAbout] = useState();
  const [url, setUrl] = useState("https://dawaa-e.com/ubaha/api/about_us");
  const [load, setLoad] = useState(false);

  useEffect(() => {
    //set about us url
    setUrl("https://dawaa-e.com/ubaha/api/about_us");
  }, []);

  useEffect(() => {
    //get data from database
    api
      .get(url)
      .then((response) => {
        if (response.data != null && response.data.result == "success") {
          setAbout(response.data.data);
          setLoad(true);
        } 
      })
      .catch((error) => {});
  }, []);

  const aboutHeader = () => {
    return(
        <h3 className="about-header">About us</h3>
    );
  }
  if (!load) {
    return <Loading />;
  }

  return (
    <div className="about">
      <Container fluid>
        <Row>
          {aboutHeader()}
        </Row>
        <Row>
          <Col md="4">
            <div className="about-image">
              <img src={about.image} alt="ubbaha" />
            </div>
          </Col>
          <Col md="8" className="pl-md-5">
            <div className="about-content">
              <img
                className="about-logo"
                src={Logo}
                width="121px"
                height="63px"
                alt="ubbaha"
              />
              <div className="about-description">
                <p>{about.description}</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
