import React from "react";

import { Route, Switch, BrowserRouter as Router } from "react-router-dom";

//import Pages
import Home from "./pages/home/Home";

import Categories from "./pages/categories/Categories";

import Category from "./pages/category/Category";

import About from "./pages/about/About";

import Contact from "./pages/contact/Contact";

import Cart from "./pages/cart/Cart";

import Login from "./pages/login/Login";

import Register from "./pages/register/Register";

import Verify from "./pages/verify/Verify";

import ForgotPassword from "./pages/forgot-password/ForgotPassword";

import VerifyPassword from "./pages/verify-password/VerifyPassword";

import Profile from "./pages/profile/Profile";

import MyOrders from "./pages/my-orders/MyOrders";

import Details from "./pages/my-orders/Details";

import Product from "./pages/product/Product";

import CartProduct from "./pages/cart-product/Product";

import OrderProduct from "./pages/order-product/Product";

import NotFound from "./pages/not-found/NotFound";

const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/verification",
    component: Verify,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/password-verification",
    component: VerifyPassword,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/categories",
    component: Categories,
  },
  {
    path: "/categories/:id",
    component: Category,
  },
  {
    path: "/about-us",
    component: About,
  },
  {
    path: "/contact-us",
    component: Contact,
  },
  {
    path: "/my-profile",
    component: Profile,
  },
  {
    path: "/my-orders",
    component: MyOrders,
  },
  {
    path: "/my-orders/:id",
    component: Details,
  },
  {
    path: "/my-orders/:parent_id/:child_id",
    component: OrderProduct,
  },
  {
    path: "/cart",
    component: Cart,
  },
  {
    path: "/home/new-arrivals/:id",
    component: Product,
  },
  {
    path: "/categories/:id/:id",
    component: Product,
  },
  {
    path: "/cart/:id",
    component: CartProduct,
  }
];

const switchRoutes = () => {
  let routeItems = [];
  routes.map((route, index) => {
    routeItems.push(
      <Route path={route.path} component={route.component} key={index} exact />
    );
  });
  routeItems.push(<Route component={NotFound} key={routes.length} exact />);
  return routeItems;
};

const Routes = () => {
  return (
    <Router>
      <Switch>{switchRoutes()}</Switch>
    </Router>
  );
};

export default Routes;
