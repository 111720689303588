//import basic react
import React, { useState, useEffect } from 'react';

//import api
import api from '../../api/Method';

//import sweet alert
import Swal from 'sweetalert2';

//import cart item css 
import './CartItem.css';

import Add from '../../assets/imgs/cart/add.svg';

import Minus from '../../assets/imgs/cart/minus.svg';

import Delete from '../../assets/imgs/cart/delete.png';

//import component from react-bootstrap
import { Row, Col, Card, Table, Button } from 'react-bootstrap';

export default function CartItem(props) {
    
    const [data, setData] = useState(props.data);

    const [url, setUrl] = useState("https://dawaa-e.com/ubaha/api/edit_order");

    const [deleteUrl, setDeleteUrl] = useState("https://dawaa-e.com/ubaha/api/delete_item")

    useEffect(()=>{
        setUrl("https://dawaa-e.com/ubaha/api/edit_order");
        setDeleteUrl("https://dawaa-e.com/ubaha/api/delete_item");
        setData(props.data);
    },[props]);

    const editQuantity = (num) => {

        let item = data.list_image.filter((item)=>item.id==data.image_id);

        let max = 0;

        if(item.length>0) {
            max = item[0].num;
        }

        if( (data.num+num>0) && (data.num+num<=max) ) {
            api.post(url,{
                image_id: data.image_id,
                sub_product: data.sub_product,
                num : data.num+num
            })
            .then(
                (response)=> {
                    if(response.data.result=="success") {
                        props.setToggle(!props.toggle);
                    }
                    else {
                        Swal.fire({
                            icon: '',
                            text: response.data.error_description,
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }
                }
            )
            .catch((error)=>{})
        }
    } 

    const deleteItem = (e)=> {
        e.preventDefault();
        Swal.fire({
          text: "Delete this item from your cart?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: 'Yes, delete',
          denyButtonText: `Cancel`,
        }).then((result) => {
          if (result.isConfirmed) {
            api
            .post(deleteUrl, {
                order_id:data.sub_product
            })
            .then((response) => {
              if (response.data!=null && response.data.result == "success") {
                Swal.fire({
                    icon: 'success',
                    text: response.data.data,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(()=>{
                    props.setToggle(!props.toggle);
                    window.location.reload();
                  })
              }
            })
            .catch((error) => {});
          }
        })
    }

  return (
    <div className="cart-item">
        <Card>
            <Row>
                <Col xs="4" lg="2" onClick={()=>window.location.href=`cart/${data.sub_product}`}>
                    <Card.Img src={data.image} height="100%" width="100%" alt="item" />
                </Col>
                <Col xs="8"  lg="10">
                    <Table>
                        <tr>
                            <td><Card.Title>{data.name}</Card.Title></td>
                        </tr>
                        <tr className="d-flex justify-content-between align-items-center flex-wrap">
                        <td>
                            <Card.Text>Price: <span className="price">{data.price} SYP</span></Card.Text>
                        </td>
                            <td className="d-flex justify-content-between align-items-center flex-wrap">
                                <Card.Text className="card-item-subtotal">Subtotal: <span className="price">{data.price * data.num} SYP</span></Card.Text>
                                <div className="d-flex justify-content-between btn-delete">
                                    <Button>
                                        <div className="icon" onClick={()=>editQuantity(1)}>
                                            <img src={Add} height="34px" width="34px" alt="add" />
                                        </div>
                                        {data.num}
                                        <div className="icon" onClick={()=>editQuantity(-1)}>
                                            <img src={Minus} height="34px" width="34px" alt="minus" />
                                        </div>
                                    </Button>
                                    <div className="delete-item">
                                        <div className="icon" onClick={deleteItem}>
                                            <img src={Delete} height="28px" width="28px" alt="delete" />
                                        </div>
                                    </div>
                                </div>
                                
                            </td>
                        </tr>
                    </Table>
                </Col>
            </Row>
        </Card>
    </div>
  );
}
