//import Basic
import React from "react";

//import component from react-bootstrap
import { Container, Row, Col, Button } from "react-bootstrap";

//import Css
import "./EmptyCart.css";

//import image
import Image from '../../assets/imgs/cart/bag.svg';

//export Empty cart Component
export default function EmptyCart() {
 
    const changeUrl = ()=> {
        window.location.href="/categories";
    }

  return (
    <div className="empty-page">
        <Container fluid>
            <Row className="justify-content-center align-items-center">
                <Col>
                    <div className="empty-content">
                        <img src={Image} width="64px" height="64px" alt="cart" />
                        <p className="empty-content-description">Your cart is currently empty</p>
                    </div>
                    <div className="empty-btn">
                        <Button onClick={changeUrl}>Shopping now</Button>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  );
}
