//import Basic
import React, { useState, useEffect, useContext } from "react";

//import api
import api from '../../api/Method';

//import sweet alert
import Swal from 'sweetalert2';

import { UserContext } from '../../context/User';

//import react-router-dom
import { BrowserRouter as Router } from "react-router-dom";

//import css
import "./Navbar.css";

//import Logo
import Logo from "../../assets/imgs/navbar/logo.png";

//import cart and user image
import User from "../../assets/imgs/navbar/user.png";
import Cart from "../../assets/imgs/navbar/cart.png";

//import Login image
import Login from "../../assets/imgs/navbar/login.svg";
import Login1 from "../../assets/imgs/navbar/login.png";

//import Logout image
import Logout from "../../assets/imgs/navbar/logout.svg";
import Logout1 from "../../assets/imgs/navbar/logout.png";

//import Order image
import Order from "../../assets/imgs/navbar/stickynote.svg";
import Order1 from "../../assets/imgs/navbar/stickynote.png";

//import Info image
import Info from "../../assets/imgs/navbar/info-circle.svg";
import Info1 from "../../assets/imgs/navbar/info.png";

//import components from bootstrap
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";

//export Component Navbar
export default function CustomNavbar() {

  const [logoutUrl, setLogoutUrl] = useState("https://dawaa-e.com/ubaha/api/logout_customer");
  
  const user = useContext(UserContext);

  const [show, setShow] = useState(true)
    
    const controlNavbar = () => {
        if (window.scrollY > 100) {
            setShow(false)
        } else {
            setShow(true)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', controlNavbar)
        return () => {
            window.removeEventListener('scroll', controlNavbar)
        }
    }, []);
  
  useEffect(() => {
    //set url
    setLogoutUrl("https://dawaa-e.com/ubaha/api/logout_customer");
  }, []);


  const logout = (e)=> {
    e.preventDefault();
    Swal.fire({
      text: 'Are you sure you want to log out?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Yes, sure',
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        api
        .post(logoutUrl, {})
        .then((response) => {
          if (response.data!=null && response.data.result == "success") {
            Swal.fire({
              icon: 'success',
              text: response.data.data,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              sessionStorage.removeItem("token");
              window.location.reload();
            })
          } else {
            Swal.fire({
              icon: 'error',
              showConfirmButton: false,
              text: response.data.error_description,
              timer: 2000
            })
          }
        })
        .catch((error) => {});
      } else if (result.isDenied) {
        Swal.fire({
          showConfirmButton: false,
          text: "Well, you're still signed in.",
          timer: 2000
        })
      }
    })
  }


  return (
    <Router>
      <Navbar className={`home-navbar ${!show && 'nav_scroll'}`} collapseOnSelect expand="lg" variant="light">
        <Container fluid>

          <Navbar.Brand href="/home">
            <img src={Logo} width="90" height="55" alt="ubbaha"/>
          </Navbar.Brand>
        
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto"></Nav>
            <Nav className="nav-right">
                <Nav.Link href="/home" className="nav-link">Home</Nav.Link>
                <Nav.Link href="/categories" className="nav-link">Categories</Nav.Link>
                <Nav.Link href="/about-us" className="nav-link">About Us</Nav.Link>
                <Nav.Link href="/contact-us" className="nav-link">Contact Us</Nav.Link>
            </Nav>
            <Nav className="nav-icon">
            <Nav.Item className="link-image">
                  <img  src={user!=null&&user.image!=""?user.image:User}  width="34" height="34" alt="user" />
                  <NavDropdown title={user!=null&&user.name!=""?user.name:"Account"} id="UserDropdown">
                    {
                      sessionStorage.getItem("token")?
                      <>
                        <NavDropdown.Item href="/my-profile">
                          <img src={Info} width="24" height="24" className="icon-dropdown" alt=""/>
                          <img src={Info1} width="24" height="24" className="icon-dropdown" alt=""/>
                          My profile
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/my-orders">
                          <img src={Order} width="24" height="24" className="icon-dropdown" alt=""/>
                          <img src={Order1} width="24" height="24" className="icon-dropdown" alt=""/>
                          My orders
                        </NavDropdown.Item>
                        <NavDropdown.Item onClick={logout}>
                          <img src={Logout} width="24" height="24" className="icon-dropdown" alt=""/>
                          <img src={Logout1} width="24" height="24" className="icon-dropdown" alt=""/>
                          Logout
                        </NavDropdown.Item>
                      </>
                      :
                      <>
                        <NavDropdown.Item href="/login">
                          <img src={Login} width="24" height="24" className="icon-dropdown" alt=""/>
                          <img src={Login1} width="24" height="24" className="icon-dropdown" alt=""/>
                          login / register
                        </NavDropdown.Item>
                      </>
                    }
                </NavDropdown>
              </Nav.Item>
             
              <Nav.Link href="/cart" className="nav-link link-image cart-link">
                <img src={Cart}  width="34" height="34" alt="cart" />
                Cart
                {
                  user!=null && user.cart>0?
                  <div className="counter">{user.cart}</div>
                  :""
                }
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>

        </Container>
      </Navbar>
    </Router>
  );
}
