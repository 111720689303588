import React from "react";

//import arrow-square-up image
import Arrow from '../../assets/imgs/scroll/arrow-square-up.png';

class ScrollButton extends React.Component {
  constructor() {
    super();
    this.state = {
      intervalId: 0,
    };
  }

  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }

  scrollToTop() {
    let intervalId = setInterval(
      this.scrollStep.bind(this),
      this.props.delayInMs
    );
    this.setState({ intervalId: intervalId });
  }

  render() {
    return (
        <div className="scroll" onClick={() => {this.scrollToTop();}}>
            <img src={Arrow} width="40px" height="40px" alt="arrow-square-up"/>
        </div>
    );
  }
}

export default ScrollButton;
