//import Basic
import React, { useState, useEffect } from "react";

//import api
import api from "../../api/Method";

//import sweet alert
import Swal from 'sweetalert2';

//import component from react-bootstrap
import { Container, Row, Col } from "react-bootstrap";

import { Form, Button } from "react-bootstrap";

//import icon
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

//import Css
import "./Register.css";

//export Register Component
export default function Register() {
  const [name, setName] = useState("");
  const [nickname, setNickname] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [url, setUrl] = useState("https://dawaa-e.com/ubaha/api/register");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    //set url
    setUrl("https://dawaa-e.com/ubaha/api/register");
  }, []);

  const submitData = (e) => {
    e.preventDefault();
    const data = {
      name,
      nickname,
      password,
      confirm_password: confirmPassword,
      mobile,
    };

    api
      .post(url, data)
      .then((response) => {
        if (response.data.result == "success") {
          Swal.fire({
            icon: 'success',
            text: `success and your code is ${response.data.data.user.code}`,
            showConfirmButton: false,
            timer: 3000
          }).then(() => {
            sessionStorage.setItem("mobile",mobile); 
            window.location.href = "/verification";
          })
        } else {
          Swal.fire({
            icon: 'error',
            text: response.data.error_description,
            showConfirmButton: false,
            timer: 2000
          })
        }
      })
      .catch((error) => {});
  };

  const login = () => {
    window.location.href = "/login";
  }

  if (sessionStorage.getItem("token") != null) {
    window.location.href = "/home";
    return "";
  }

  return (
    <div className="register">
      <Container fluid>

        <Row className="d-flex justify-content-center align-items-center">
          <Col md="7" xl="5">
            <Form onSubmit={submitData}>
              <Form.Group className="register-header">
                <h3>New account</h3>
                <p>Please fill in the information below:</p>  
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="text"
                  required
                  minLength="2"
                  name="first-name"
                  placeholder="First name"
                  onChange={(e) => setName(e.target.value)}
                />
                <Form.Control
                  type="text"
                  required
                  minLength="2"
                  name="last-name"
                  placeholder="Last name"
                  onChange={(e) => setNickname(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="text"
                  required
                  minLength="10"
                  maxLength="10"
                  name="mobile"
                  placeholder="Mobile number"
                  onChange={(e) => setMobile(e.target.value)}
                />
              </Form.Group>
              <div className="register-password-main">
              
                <Form.Group className="register-password">
                  <Form.Control
                    type={showPassword?"text":"password"}
                    required
                    minLength="8"
                    name="password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {
                    showPassword?
                    <VisibilityOffIcon className="password-icon" onClick={()=>setShowPassword(!showPassword)} />
                    :
                    <VisibilityIcon className="password-icon" onClick={()=>setShowPassword(!showPassword)} />
                  }
                </Form.Group>
                <Form.Group className="register-password">
                  <Form.Control
                    type={showConfirmPassword?"text":"password"}
                    required
                    minLength="8"
                    name="confirm-password"
                    placeholder="Confirm password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    
                  />
                  {
                    showConfirmPassword?
                    <VisibilityOffIcon className="password-icon" onClick={()=>setShowConfirmPassword(!showConfirmPassword)} />
                    :
                    <VisibilityIcon className="password-icon" onClick={()=>setShowConfirmPassword(!showConfirmPassword)} />
                  }
                </Form.Group>
              </div>
            
              <Form.Group className="form-button">
                <Button className="w-100" type="submit">
                  Register
                </Button>
              </Form.Group>
              <Form.Group>
                <p className="append-text d-inline-block">Already have an account? </p>
                <span onClick={login}>
                  <p className="d-inline-block ml-1"> Login</p>
                </span>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
