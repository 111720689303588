//import basic react
import React, { useState, useEffect } from 'react';

//import cart item css 
import './MyOrderItem.css';

//import component from react-bootstrap
import { Row, Col, Card } from 'react-bootstrap';

export default function MyOrderItem(props) {
    
    const [data, setData] = useState(props.data);

    useEffect(()=>{
        setData(props.data);
        console.log(props.data)
    },[]);


  return (
    <div className="my-order-item">
        <Card>
            <Row>
                <Col xs="4" md="3" lg="2">
                    <Card.Img onClick={()=>window.location.href=`/my-orders/${props.parentID}/${data.sub_product}`} src={data.image} height="100%" width="100%" alt="item" />
                </Col>
                <Col xs="8" md="9" lg="10">
                   
                    <Card.Title>{data.name}</Card.Title>
                    
                    <Card.Body className="d-flex align-items-center justify-content-lg-between flex-wrap">
                        <Col md="3" lg="4">
                            <Card.Text>Quantity: {data.num}</Card.Text>
                        </Col>
                       
                        <Col md="4" lg="4" className="text-left">
                            <Card.Text>Price: {data.price} SYP</Card.Text>
                        </Col>
                        <Col md="5" lg="4" className="text-left">
                            <Card.Text className="subtotal">Subtotal: {parseInt(data.price*data.num)} SYP</Card.Text>
                        </Col>
                    </Card.Body>
                    
                </Col>
            </Row>
        </Card>
    </div>
  );
}
