//import basic react
import React, { useState, useEffect } from 'react';

//import api
import api from '../../api/Method';

//import sweet alert
import Swal from 'sweetalert2';

//import About css 
import './Contact.css';

//import components from react-bootstrap
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

//import loading page
import Loading from '../loading/Loading';

//import social icons
import Facebook from '../../assets/imgs/contact/facebook.png';
import Instagram from '../../assets/imgs/contact/instagram.png';
import Youtupe from '../../assets/imgs/contact/youtube.png';
import Email from '../../assets/imgs/contact/email.png';
import Phone from '../../assets/imgs/contact/phone.png';
import Mobile from '../../assets/imgs/contact/mobile.png';
import Location from '../../assets/imgs/contact/location.png';
import SendMessage from '../../assets/imgs/contact/contact.png';

export default function Contact() {

  const [contact, setContact] = useState();
  const [url, setUrl] = useState("https://dawaa-e.com/ubaha/api/contact_us_info");
  const [messageUrl, setMessageUrl] = useState("https://dawaa-e.com/ubaha/api/contact_us");
  const [load, setLoad] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [msg, setMessage] = useState("");
  const [toggle, setToggle] = useState(false);

  useEffect(()=>{
    //set url
    setUrl("https://dawaa-e.com/ubaha/api/contact_us_info");
    setMessageUrl("https://dawaa-e.com/ubaha/api/contact_us")
},[]);

  useEffect(()=>{
     //get data from database
     api.get(url)
     .then(
         (response)=> {
             if(response.data.result=="success") {
                setContact(response.data.data);
                setLoad(true);
             }
        }
     )
     .catch((error)=>{})
  },[]);

  useEffect(()=>{
    setName("");
    setEmail("");
    setMessage("");
  },[toggle]);

  const contactHeader = () => {
    return(
        <h3 className="contact-header">Contact us</h3>
    );
}

  const sendMessage = (e) => {
    e.preventDefault();
    const data = {
      name,
      email,
      msg
    }
    api.post(messageUrl,data)
     .then(
         (response)=> {
          Swal.fire({
            icon: 'success',
            text: response.data,
            showConfirmButton: false,
            timer: 2000
          }).then(() => {
            setToggle(!toggle);     
            })   
         }
     )
     .catch((error)=>{})
  }

  if(!load) {
    return  <Loading />;
  }

  return (
    <div className="contact">
      <Container fluid>
        <Row>
          {contactHeader()}
        </Row>
        <Row>
          <Col md="5" xl="4" className="order-md-1 order-12">
            <div className="social-map">
              <Row>
                <Col xs="12">
                  <div className="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3326.560364610449!2d36.29469758479908!3d33.512813580755974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1518e77d0f51b865%3A0x2014ca7e4195de13!2zVWJiYWhhIC0g2KPZj9io2ZHZjtmH2Kk!5e0!3m2!1sar!2snl!4v1645007508403!5m2!1sar!2snl" allowfullscreen="" loading="lazy"></iframe>
                  </div>
                </Col>
                <Col xs="12" id="follow">
                  <div className="social">
                    <p>Follow us on</p>
                    <div className="icons">
                      <img src={Facebook} onClick={()=>window.location.href=contact.facebook} width="36px" height="36px" alt="facebook" />
                      <img src={Instagram} onClick={()=>window.location.href=contact.instagram} width="36px" height="36px" alt="instagram" />
                      {/* <img src={Youtupe} onClick={()=>window.location.href=contact.youtube} width="36px" height="36px" alt="youtupe" /> */}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md="6" xl="6" className="order-md-12 order-1 offset-md-1">
            <div className="contact-message">
              <div className="address">
                <div className="address-item">
                  <img src={Mobile} width="36px" height="36px" alt="mobile-number" />
                  <a href={`tel:${contact.mobile}`}>{contact.mobile?contact.mobile:"mobile number..."}</a>
                </div>
                <div className="address-item">
                  <img src={Phone} width="36px" height="36px" alt="phone-number" />
                  <a href={`tel:011${contact.phone}`}>{contact.phone?contact.phone:"phone number..."}</a>
                </div>
                <div className="address-item">
                  <img src={Email} width="36px" height="36px" alt="email" />
                  <a href={`mailto:${contact.email}`}>{contact.email?contact.email:"email@gmail.com"}</a>
                </div>
                <div className="address-item">
                  <img src={Location} width="36px" height="36px" alt="location" />
                  <a href="https://goo.gl/maps/RPyy6MUaTjehEhG37">{contact.address?contact.address:"address"}</a>
                </div>
              </div>
             
              <div className="social" id="follow-sm">
                <p>Follow us on</p>
                <div className="icons">
                  <img src={Facebook} onClick={()=>window.location.href=contact.facebook} width="36px" height="36px" alt="facebook" />
                  <img src={Instagram} onClick={()=>window.location.href=contact.instagram} width="36px" height="36px" alt="instagram" />
                  <img src={Youtupe} onClick={()=>window.location.href=contact.youtube} width="36px" height="36px" alt="youtupe" />
                </div>
              </div>
                
              
              <div className="message">
                <p>Leave a message</p>
                <Form onSubmit={sendMessage}>
                  <Form.Control type="text" value={name} required placeholder="Your Name" onChange={(e)=>setName(e.target.value)} />
                  <Form.Control type="email" value={email} required placeholder="Your E-mail"  onChange={(e)=>setEmail(e.target.value)} />
                  <Form.Control as="textarea" value={msg} required placeholder="Message"  onChange={(e)=>setMessage(e.target.value)} />
                  <Button type="submit"><img src={SendMessage} width="36px" height="36px" alt="send" />Send</Button>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
