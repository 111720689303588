//import basic react
import React, { useState, useEffect } from 'react';

//import Checkout css 
import './Checkout.css';

//import component from react bootstrap
import { Table, Button, Container, Row, Col} from 'react-bootstrap';

//import checkout icon
import CheckoutIcon from '../../assets/imgs/cart/checkout.png';

//import modal
import Modal from '../../components/checkout-modal/Modal';

export default function Checkout(props) {
    const [subtotal, setSubtotal] = useState("");
    const [modalShow, setModalShow] = useState(false);
    
    useEffect(()=>{
        setSubtotal(props.subtotal);
    },[props]);

    return (
        <div className="checkout-cart text-center">
            <Container>
                <Row>
                    <Col>
                        <div className="checkout-subtotal">
                            <Table>
                                <tr>
                                    <td className="text-center">Grand total: {subtotal} SYP</td>
                                </tr>
                            </Table>
                            <Button onClick={() => setModalShow(true)}>
                                <img src={CheckoutIcon} className="mr-2" width="34px" height="34px" alt="checkout" />
                                Checkout
                            </Button>
                            <Modal
                                show={modalShow}
                                orderID = {props.orderID}
                                onHide={() => setModalShow(false)}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );    
}
