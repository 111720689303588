//import basic react
import React, { useState, useEffect } from 'react';

//import api
import api from '../../api/Method';

//import cart css 
import './Cart.css';

//import cart item component
import CartItem from '../../components/cart-item/CartItem';

//import component from react-bootstrap
import { Container, Row, Col } from 'react-bootstrap';

//import checkout component
import Checkout from '../../components/checkout/Checkout'; 

//import loading
import Loading from '../loading/Loading';

//import empty cart component
import EmptyCart from '../empty-cart/EmptyCart';


export default function Cart() {

  const [url, setUrl] = useState("https://dawaa-e.com/ubaha/api/all_card");
  
  const [load, setLoad] = useState(false);

  const [toggle, setToggle] = useState(false);

  var [subtotal, setSubtotal] = useState(0);
  
  const [cart, setCart] = useState([]);

  const [orderID, setOrderID] = useState("");
 

  useEffect(()=>{
    //set url
    setUrl("https://dawaa-e.com/ubaha/api/all_card");
  },[]);
  
   useEffect(()=>{
    //get data from database
    api.get(url)
    .then(
        (response)=> {
            if(response.data.result=="success") { 
              setCart(response.data.data);
              setOrderID(response.data.data);
              setLoad(true);
            }
        }
    )
    .catch((error)=>{})
  },[toggle]);

  const cartHeader = () => {
    return(
        <h3 className="cart-header">Cart</h3>
    );
}

  const cartItems = () => {
    const items = [];
    var sub = 0;
    if(cart[0]!=null && cart[0].product.length>0) {
      cart[0].product.map((item,index)=>{
        if(item!=null) {
          sub+=item.price * item.num;
          items.push(<Col sm="12" key={index}><CartItem data={item} toggle={toggle} setToggle={(value)=>setToggle(value)} /></Col>)
        }
      });
      subtotal = sub;
      return items;
    }
  }

  if (sessionStorage.getItem("token") == null) {
    window.location.href = "/login";
    return "";
  }

  if(!load) {
    return <Loading />;
  }
  
  if(load && (cart[0]==null || cart[0].product.length==0)) {
    return <EmptyCart />;
  }

  return (
    <div className="cart">
      <Container fluid>
        <Row>
          {cartHeader()}
        </Row>
        <Row>
          {cartItems()}
         
        </Row>
      </Container>
      <Checkout orderID={orderID} subtotal={subtotal} />
    </div>
  );
}
