//import basic react
import React, { useState, useEffect } from "react";

//import api
import api from "../../api/Method";

//import Product css
import "./Product.css";

//import loading page
import Loading from "../loading/Loading";

//import icon (add to cart)
import Add from "../../assets/imgs/product/add-cart.png";

//import gallery
// import Gallery from "../../components/product-gallery/Gallery";

//import images
import AddItem from '../../assets/imgs/cart/add.svg';

import Minus from '../../assets/imgs/cart/minus.svg';

//import sweet alert
import Swal from 'sweetalert2';

//import components from react-bootstrap
import { Container, Row, Col, Card, Button, Table } from "react-bootstrap";

export default function Product(props) {
  const [id, setID] = useState(props.match.params.id);

  const [url, setUrl] = useState("https://dawaa-e.com/ubaha/api/one_product/");
  const [imageUrl, setImageUrl] = useState(
    "https://dawaa-e.com/ubaha/api/one_product/"
  );
  const [cartUrl, setCartUrl] = useState("https://dawaa-e.com/ubaha/api/card");
  const [cart, setCart] = useState("https://dawaa-e.com/ubaha/api/all_card");
  const [editCartUrl, setEditCartUrl] = useState(
    "https://dawaa-e.com/ubaha/api/edit_order"
  );

  const [quantity, setQuantity] = useState(1);
  const [itemQuantity, setItemQuantity] = useState(1);
  const [product, setProduct] = useState();
  const [load, setLoad] = useState(false);
  const [items, setItems] = useState([]);
  const [images, setImages] = useState([]);
  const [subImages, setSubImages] = useState([]);
  // const [imageID, setImageID] = useState(0);
  const [imageValueID, setImageValueID] = useState("");
  const [defaultImage, setDefaultImage] = useState("");

  useEffect(() => {
    //set url
    setUrl("https://dawaa-e.com/ubaha/api/one_product/");
    setImageUrl("https://dawaa-e.com/ubaha/api/product_ome_image");
    setCart("https://dawaa-e.com/ubaha/api/card");
    setCartUrl("https://dawaa-e.com/ubaha/api/card");
    setEditCartUrl("https://dawaa-e.com/ubaha/api/edit_order");
  }, []);

  useEffect(() => {
    //get data from database
    api
      .getById(url, id)
      .then((response) => {
        
        if (response.data != null && response.data.result == "success") {
      
          setProduct(response.data.data.data[0]);
          setDefaultImage(response.data.data.data[0].image);

          if (response.data.data.data[0].item.length > 0) {
            setItems(response.data.data.data[0].item);  
          }

          if (response.data.data.data[0].list_image.length > 0) {
            setImages(response.data.data.data[0].list_image);
            setSubImages(response.data.data.data[0].list_image[0].sub_image);
            setImageValueID(response.data.data.data[0].list_image[0].id);
            setItemQuantity(response.data.data.data[0].list_image[0].num);
          }

          setLoad(true);
        }
         
      })
      .catch((error) => {});
  }, []);

  const Items = (items) => {
  
    let allItems = [];
    if (items.length > 0) {
      items.map((item, index) => {
        if (item != null) {
          allItems.push(
            <td key={index}>
              <div className="item">
                {item.value}
              </div>
            </td>

          );
        }
      });
      return allItems;
    }
  };

  const tableItems = () => {
    let tableTr = [];
    if( items.length>0) {
      items.map((item,index)=>{
        if(item!=null && item!=undefined) {
          tableTr.push(
            <>
            <tr key={index}>
            {/* className="d-flex table-title" */}
             <td>
             <Card.Text>{item.name}: </Card.Text>
              
             </td>
               {
                 Items(item.parent)
               }
           </tr>
          
           </>
           )  
        }
      })
    }
    return tableTr;
  }

  const editQuantity = (num) => {
    if(num+quantity<=itemQuantity && num+quantity>=1) {
      setQuantity(num+quantity);
    }
  }

  const addToCart = (e) => {
    e.preventDefault();
    api
      .post(cartUrl, {
        image_id: imageValueID,
        sub_product: product.id,
        num: quantity
      })
      .then((response) => {
        if (response.data!=null && response.data.result == "success") {
         
          Swal.fire({
            icon: 'success',
            text: response.data.result,
            showConfirmButton: false,
            timer: 2000
          }).then(() => {
            window.location.href="/cart";
          })
        }
        else {
          Swal.fire({
            icon: 'error',
            showConfirmButton: false,
            text: response.data.error_description,
            timer: 2000
          })
        }
      })
      .catch((error)=>{
        Swal.fire({
          icon: 'error',
          showConfirmButton: false,
          text: error.response.data.error_description,
          timer: 2000
        }).then(()=>{
          window.location.href="/login"
        })
      })
  }

  if (!load) {
    return <Loading />;
  }

  return (
    <div className="product">
      <Container fluid>
        <Row>
          <Col sm="4" md="5" lg="4">
            <div className="product-images">
              {/* {images.length > 0 ? (
                <Gallery
                  gallery={images}
                  setImageID={(value) => setImageID(value)}
                />
              ) : (
                <Card>
                  <Card.Img src={defaultImage} width="100%" />
                </Card>
              )} */}
                <Card>
                  <Card.Img src={defaultImage} width="100%" />
                </Card>
            </div>
          </Col>
          <Col sm="8" md="7" lg="8">
            <div className="product-details">
              <Card>
                
                <Card.Title>{product.name}</Card.Title>
                <Card.Text className="description">
                  {product.description_ar}
                </Card.Text>

                <Card.Body>
                <table>
                  {
                    tableItems()
                  }
                </table>
                  
                  <div className="card-row">
                    <Card.Text className="title">Price: </Card.Text>
                    <div className="price">
                      <p>{product.price} SYP</p>
                    </div>
                  </div>
                  
                  {images.length > 0 ? (
                    <div className="card-row">
                      <Card.Text className="title">Quantity: </Card.Text>
                      <div className="quantity">
                       
                          <Button>
                              <div className="icon" onClick={()=>editQuantity(1)}>
                                  <img src={AddItem} height="34px" width="34px" alt="add" />
                              </div>
                              {quantity}
                              <div className="icon" onClick={()=>editQuantity(-1)}>
                                  <img src={Minus} height="34px" width="34px" alt="minus" />
                              </div>
                          </Button>   
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </Card.Body>
                
                  <Card.Footer>
                    <div className="payment">
                      <Row>
                        <Col md="12"> 
                            <Button
                              className="add-cart"
                              onClick={addToCart}
                            >
                              <img
                                src={Add}
                                height="34px"
                                width="34px"
                                alt="add-cart"
                              />
                              Add to cart
                            </Button>  
                        </Col>
                      </Row>
                    </div>
                  </Card.Footer>
                
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
