//import basic react
import React, { useState, useEffect } from "react";

//import api
import api from "../../api/Method";

//import Product css
import "../product/Product.css";

//import loading page
import Loading from "../loading/Loading";

//import sweet alert
import Swal from 'sweetalert2';


//import components from react-bootstrap
import { Container, Row, Col, Card } from "react-bootstrap";

export default function Product(props) {
  
  const [url, setUrl] = useState("https://dawaa-e.com/ubaha/api/one_order/");
  const [parentId] = useState(props.match.params.parent_id);
  const [childId] =  useState(props.match.params.child_id);
  const [quantity, setQuantity] = useState(1);
  const [product, setProduct] = useState();
  const [load, setLoad] = useState(false);
  const [items, setItems] = useState([]);
  const [defaultImage, setDefaultImage] = useState("");

  useEffect(() => {
    //set url
    setUrl("https://dawaa-e.com/ubaha/api/one_order/");
  }, []);

  useEffect(() => {
    //get data from database
    api
      .getById(url, parentId)
      .then((response) => {
        
        if (response.data != null && response.data.result == "success") {
          let elements = response.data.data.data[0].product.filter((item)=>item.sub_product==childId);
          let element = elements[0];
          setProduct(element);
          setDefaultImage(element.image);
          setQuantity(element.num);
          if (element.item.length > 0) {
            setItems(element.item);  
          }
          setLoad(true);
        }
      })
      .catch((error) => {});
  }, []);

  const tableItems = () => {
    let tableTr = [];
    if( items.length>0) {
      items.map((item,index)=>{
        if(item!=null && item!=undefined) {
          tableTr.push(
            <>
            <tr key={index}>
             <td>
             <Card.Text>{item.name}: </Card.Text>
              
             </td>
              <td>
                <div className="item">
                  {item.value}
                </div>
              </td>
             
           </tr>
          
           </>
           )  
        }
      })
    }
    return tableTr;
  }

  if (!load) {
    return <Loading />;
  }

  return (
    <div className="product">
      <Container fluid>
        <Row>
          <Col sm="4" md="5" lg="4">
            <div className="product-images">
                <Card>
                  <Card.Img src={defaultImage} width="100%" />
                </Card>
            </div>
          </Col>
          <Col sm="8" md="7" lg="8">
            <div className="product-details">
              <Card>
                
                <Card.Title>{product.name}</Card.Title>
                <Card.Text className="description">
                  {product.description}
                </Card.Text>

                <Card.Body>
                <table>
                  {
                    tableItems()
                  }
                </table>
                  
                  <div className="card-row">
                    <Card.Text className="title">Price: </Card.Text>
                    <div className="price">
                      <p>{product.price} SYP</p>
                    </div>
                  </div>
                  
                  <div className="card-row">
                    <Card.Text className="title">Quantity: </Card.Text>
                    <div className="quantity">
                      <p>{quantity}</p>
                    </div>
                  </div>
                
                </Card.Body>

              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
