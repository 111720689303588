//import basic react
import React, { useState, useEffect } from 'react';

//import api
import api from '../../api/Method';

//import Categories css 
import './Categories.css';

//import loading page
import Loading from '../loading/Loading';

//import components from react-bootstrap
import { Container, Row, Col, Card } from 'react-bootstrap';

export default function Categories() {

    const [categories, setCategories] = useState([]);
    const [url, setUrl] = useState("https://dawaa-e.com/ubaha/api/all_category");
    const [load, setLoad] = useState(false);

    useEffect(()=>{
        //set url
        setUrl("https://dawaa-e.com/ubaha/api/all_category");
    },[]);

    useEffect(()=>{
        //get data from database
        api.get(url)
        .then(
            (response)=> {
                if(response.data!=null && response.data.result=="success") {
                    setCategories(response.data.data);
                    setLoad(true);
                }
            }
        )
        .catch((error)=>{})
    },[]);


    const categoriesHeader = () => {
        return(
            <h3 className="categories-header">All categories</h3>
        );
    }

    const categoryItems = () => {
        let items = [];
        if(categories.length>0) {
          categories.map((item,index)=>{
            if(item!=null && item!=undefined) {
                items.push(
                    <Col xs="6" sm="4" md="3" lg="4" xl="3" key={index}>
                        <Card className="category-item" style={{backgroundImage:`url(${item.image})`}} onClick={()=>window.location.href=`categories/${item.id}`}>
                        </Card>
                        <Card.Text className="category-item-name">{item.name}</Card.Text>
                    </Col>
                )   
            }
            });
            return items;
        }
    }

    if(!load) {
        return <Loading />;
    }

    return (
        <div className="categories">
            <Container fluid>
                <Row>
                    {categoriesHeader()}
                </Row>
                <Row>
                    {categoryItems()}
                </Row>
            </Container>
        </div>
    );
}
