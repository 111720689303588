//import basic react
import React, { useState, useEffect } from 'react';

//import api
import api from '../../api/Method';

//import Categories css 
import './HotCategories.css';

//import components from react-bootstrap
import { Container, Row, Col, Card } from 'react-bootstrap';

//import icon see all
import SeeAll from '../../assets/imgs/hot-categories/see-all.svg';

export default function HotCategories() {
    const [categories, setCategories] = useState([]);
    const [url, setUrl] = useState("https://dawaa-e.com/ubaha/api/category");
    const [load, setLoad] = useState(false);

    useEffect(()=>{
        //set url
        setUrl("https://dawaa-e.com/ubaha/api/category");
    },[]);
  
    useEffect(()=>{
        //get data from database
        api.get(url)
        .then(
            (response)=> {
                if(response.data!=null && response.data.result=="success") {         
                   setCategories(response.data.data);
                   setLoad(true);
                }
            }
        )
        .catch((error)=>{})
     },[]);

    const categoryItems = () => {
        let items = [];
        if(categories.length>0) {
            categories.map((item,index)=>{
                if(item!=null && item!=undefined) {
                    items.push(
                        <Col  xs="6" sm="4" md="3" lg="4" xl="3" key={index}>
                            <Card className="hot-category" style={{backgroundImage:`url(${item.image})`}} onClick={()=>window.location.href=`categories/${item.id}`}>
                            </Card>
                            <Card.Text>{item.name}</Card.Text>
                        </Col>
                    )
                }
            });
            return items;
        }
    }

    const CategoriesHeader = () => {
        return(
            <div className="hot-categories-header">
                <h2 className="">Hot Categories</h2>
                <div className="see-all" onClick={()=>window.location.href="/categories"}>
                    <p className="mb-0">See All</p>
                    <div className="see-all-images">
                        <img className="see-all-image" src={SeeAll} width="100%" height="100%" alt="see-all" />
                        <img src={SeeAll} width="100%" height="100%" alt="see-all" />
                    </div>
                </div>
            </div>
        );
    }

    if(!load) {
        return "";
    }

    return (
        <div className="hot-categories">
            <Container fluid>
                <Row>
                    {CategoriesHeader()}
                </Row>
                <Row>
                    {categoryItems()}
                </Row>
            </Container>
        </div>
    );
}
