//import basic react
import React, { useState, useEffect } from 'react';

//import api
import api from '../../api/Method';

//import Categories css 
import './Category.css';

//import loading page
import Loading from '../loading/Loading';

//import components from react-bootstrap
import { Container, Row, Col, Card } from 'react-bootstrap';

//import empty cart component
import EmptyCategory from '../empty-category/EmptyCategory';


export default function Category(props) {
    const [category, setCategory] = useState({});
    const [products, setProducts] = useState([]);
    const [url, setUrl] = useState("https://dawaa-e.com/ubaha/api/category_product/");
    const [id, setID] = useState(props.match.params.id);
    const [load, setLoad] = useState(false);

    useEffect(()=>{
        //set url
        setUrl("https://dawaa-e.com/ubaha/api/category_product/");

        //set category id
        setID(props.match.params.id);

    },[]);

    useEffect(()=>{
        //get data from database
        api.getById(url,id)
        .then(
            (response)=> {
                if(response.data!=null && response.data.result=="success") {
                    setCategory(response.data.data);
                    setProducts(response.data.data.data);
                    setLoad(true);
                }
            }
        )
        .catch((error)=>{})
    },[]);

    const changeUrl = (productID) => {
        
        window.location.href=`${window.location.pathname}/${productID}`;
    }

    const categoryHeader = () => {
        return(
            <h3 className="category-header">{products[0].category}</h3>
        );
    }

    const productItems = () => {
        let items = [];
        if(products.length>0) {
            products.map((item,index)=>{
                if(item!=null && item!=undefined) {
                    items.push(
                        <Col xs="6" sm="4" md="3" lg="4" xl="3" key={index}>
                            <Card className="category-product">
                                <Card.Img src={item.image} height="100%" width="100%" alt={item.name} onClick={()=>changeUrl(item.id)} />
                                <Card.Body>
                                    <Card.Text className="category-product-name">{item.name}</Card.Text>
                                    <Card.Text className="category-product-description">{item.description}</Card.Text>
                                    <Card.Text className="category-product-price">{item.price} SYP</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    )
                }
            });
            return items;
        }
    }
   
    if(!load) {
        return <Loading />;
    }

    if(products.length<=0) {
        return <EmptyCategory />
    }

    return (
        <div className="category">
            <Container fluid>
                <Row>
                    {categoryHeader()}
                </Row>
                <Row>
                    {productItems()}
                </Row>
            </Container>
        </div>
    );
}
