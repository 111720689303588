//import Basic
import React from "react";

//import component from react-bootstrap
import { Container, Row, Col, Button } from "react-bootstrap";

//import Css
import "./EmptyOrders.css";

//import image
import Image from '../../assets/imgs/orders/stickynote.svg';

//export Empty cart Component
export default function EmptyCart() {
 
 
  return (
    <div className="orders-empty-page">
        <Container fluid>
            <Row>
                <Col>
                    <div className="empty-content">
                        <div className="empty-img">
                            <img src={Image} width="64px" height="64px" alt="order" />
                        </div>
                        <p className="empty-content-description">You don't have any orders yet</p>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  );
}
