//import basic react
import React, { useState, useEffect } from 'react';

//import api
import api from '../../api/Method';

//import Arrivals css 
import './Arrivals.css';

//import components from react-bootstrap
import { Container, Row, Col, Card } from 'react-bootstrap';

export default function Arrivals() {
    const [products, setProducts] = useState([]);
    const [url, setUrl] = useState("https://dawaa-e.com/ubaha/api/product");
    const [load, setLoad] = useState(false);
 
    useEffect(()=>{
        //set url
        setUrl("https://dawaa-e.com/ubaha/api/product");
    },[]);

    useEffect(()=>{
       //get data from database
       api.get(url)
       .then(
           (response)=> {
               if(response.data!=null && response.data.result=="success") {         
                  setProducts(response.data.data.data);
                  setLoad(true);
               }
           }
       )
       .catch((error)=>{})
    },[]);

    const productItems = () => {
        let items = [];
        if(products.length>0) {
            products.map((item,index)=>{
                if(item!=null && item!=undefined) {
                    items.push(
                        <Col  xs="6" sm="4" md="3" lg="4" xl="3" key={index} onClick={()=>window.location.href=`home/new-arrivals/${item.id}`}>
                            <Card className="arrival">
                                <Card.Img src={item.image} height="100%" width="100%" alt={item.name} />
                                <Card.Body>
                                    <Card.Text className="arrival-name">{item.name}</Card.Text>
                                    <Card.Text className="arrival-description">{item.description}</Card.Text>
                                    <Card.Text className="arrival-price">{item.price} SYP</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    )
                }  
            });
            return items;
        }
    }

    const ArrivalsHeader = () => {
        return(
            <div className="arrivals-header">
                    <h2>New Arrivals</h2>
            </div>
        );
    }

    if(!load) {
        return "";
    }

    return (
        <div className="arrivals">
            <Container fluid>
                <Row>
                    {ArrivalsHeader()}
                </Row>
                <Row>
                    {productItems()}
                </Row>
            </Container>
        </div>
    );
}
