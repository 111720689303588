import axios from "axios";

const config = {
  headers: {
    Authorization: "Bearer " + sessionStorage.getItem("token"),
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    lang: "en",
  },
};

const get = async (url) => {
  const response = await axios.get(url, config);
  return response;
};

const getById = async (url, id) => {
  const response = await axios.get(url + id, config);
  return response;
};

const deleteApi = async (url, id) => {
  const response = await axios.delete(url + id, config);
  return response;
};

const update = async (url, data, id) => {
  const response = await axios.post(url + id, data, config);
  return response;
};

const post = async (url, data) => {
  let response = await axios.post(url, data, config);
  return response;
};

export default { get, getById, post, deleteApi, update };
