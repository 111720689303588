//import basic react
import React, { useState, useEffect ,useContext } from 'react';

//import api
import api from '../../api/Method';

//import cart css 
import './Details.css';

//import sweet alert
import Swal from 'sweetalert2';

//import cart item component
import MyOrderItem from '../../components/my-order-item/MyOrderItem';

//import component from react-bootstrap
import { Container, Row, Col, Button } from 'react-bootstrap';

//import loading
import Loading from '../loading/Loading';


export default function Details(props) {

  const [url, setUrl] = useState("https://dawaa-e.com/ubaha/api/one_order/");

  const [cancelUrl, setCancelUrl] = useState("https://dawaa-e.com/ubaha/api/delete_order");
  
  const [load, setLoad] = useState(false);

  const [myOrder, setMyOrder] = useState({});

  const [products, setProducts] = useState([]);

  const [id, setID] = useState(props.match.params.id);

 
  useEffect(()=>{
    //set url
    setUrl("https://dawaa-e.com/ubaha/api/one_order/");
    setCancelUrl("https://dawaa-e.com/ubaha/api/delete_order");

    //set id
    setID(props.match.params.id);
  },[]);

  useEffect(()=>{ 
  //get data from database
  api.getById(url, id)
  .then(
      (response)=> {
          if(response.data!=null && response.data.result=="success") {  
            setMyOrder(response.data.data.data[0]);
            setProducts(response.data.data.data[0].product);
            setLoad(true);      
          }
      }
  )
  .catch((error)=>{})
  },[]);

  const cancelOrder = (e) => {
    e.preventDefault();
   
    Swal.fire({
      text: 'Are you sure you want to cancel this order?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Yes, sure',
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        api.post(cancelUrl, {
          order_id:id
        })
        .then(
            (response)=> {
                if(response.data!=null && response.data.result=="success") {
                  Swal.fire({
                    icon: '',
                    text: response.data.data,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    window.location.href="/my-orders";
                  })
                }
                else {
                  Swal.fire({
                    icon: 'error',
                    showConfirmButton: false,
                    text: response.data.error_description,
                    timer: 2000
                  })
            }
          }
        )
        .catch((error)=>{})
      } 
    })
  }

  const detailsHeader = () => {
    return (
      <div className="my-order-details-header">
        <Container fluid>
          <Row>
            <Col lg="6">
              <div className="my-orders-item-details">
                <div className="d-flex align-items-center flex-wrap my-order-item-details-header">
                  <div className="order-details-number"><p>{`Order #${myOrder.id}`}</p></div>
                  <div className="order-details-status">
                    {myOrder.status==0?<Button className="order-details-installed-btn">{myOrder.status_text}</Button>:""}
                    {myOrder.status==1?<Button className="order-details-delivered-btn">{myOrder.status_text}</Button>:""}
                    {myOrder.status==2?<Button className="order-details-canceled-btn">{myOrder.status_text}</Button>:""}
                  </div>
                </div>
                <div className="">
                    <div className="order-details-on"><p>{`Order on: ${myOrder.date}`}</p></div>
                    <div className="order-details-method"><p>{`Receiving method: ${myOrder.status_type}`}</p></div>
                    {
                      myOrder.address=="null" || myOrder.address==null ?"": <div className="order-details-address"><p>{`Address: ${myOrder.address}`}</p></div>
                    }
                </div>
              </div>
            </Col>
            <Col lg="6" className="d-flex justify-content-end align-items-end">
              <div className="grand-total">
                <p>{`Grand total: ${myOrder.total} SYP`}</p>
                {
                  myOrder.status==0?
                  <Button className="cancel-order" onClick={(e)=>cancelOrder(e)} >Cancel the order</Button>
                  :""
                }
                
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  const productItems = () => {
    const items = [];
    if(products.length>0) {
      products.map((item,index)=>{
        if(item!=null && item!=undefined) {
          items.push(<Col md="12" key={index}><MyOrderItem parentID={myOrder.id} data={item} /></Col>)
        }
      });
      return items;
    }
  }

  if (sessionStorage.getItem("token") == null) {
    window.location.href = "/login";
    return "";
  }

  if(!load) {
    return <Loading />;
  }

  return (
    <div className="order-details">
      {detailsHeader()}
      
      <Container fluid>
        <Row className="my-order-details">
          {productItems()}
        </Row>
      </Container>
    </div>
  );
}
