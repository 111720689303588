//import basic react
import React, { useState, useEffect } from "react";

//import api
import api from "../../api/Method";

//import Product css
import "../product/Product.css";

//import loading page
import Loading from "../loading/Loading";

//import icon (add to cart)
import Add from "../../assets/imgs/product/add-cart.png";

//import images
import AddItem from '../../assets/imgs/cart/add.svg';

import Minus from '../../assets/imgs/cart/minus.svg';

//import sweet alert
import Swal from 'sweetalert2';


//import components from react-bootstrap
import { Container, Row, Col, Card, Button, Table } from "react-bootstrap";

export default function Product(props) {
  

  const [cart, setCart] = useState("https://dawaa-e.com/ubaha/api/all_card");
  const [editCartUrl, setEditCartUrl] = useState("https://dawaa-e.com/ubaha/api/edit_order");
  const [id, setID] = useState(props.match.params.id);
  const [quantity, setQuantity] = useState(1);
  const [itemQuantity, setItemQuantity] = useState(1);
  const [product, setProduct] = useState();
  const [load, setLoad] = useState(false);
  const [items, setItems] = useState([]);
  const [images, setImages] = useState([]);
  const [imageValueID, setImageValueID] = useState("");
  const [defaultImage, setDefaultImage] = useState("");

  useEffect(() => {
    //set url
    setCart("https://dawaa-e.com/ubaha/api/card");
    setEditCartUrl("https://dawaa-e.com/ubaha/api/edit_order");
  }, []);

  useEffect(() => {
    //get data from database
    api
      .get(cart)
      .then((response) => {
        
        if (response.data != null && response.data.result == "success") {
          let elements = response.data.data[0].product.filter((item)=>item.sub_product==id);
          let element = elements[0];
          setProduct(element);
          setDefaultImage(element.image);
          setQuantity(element.num);


          if (element.item.length > 0) {
            setItems(element.item);  
          }

          if (element.list_image.length > 0) {
            setImages(element.list_image);
            setImageValueID(element.list_image[0].id);
            setItemQuantity(element.list_image[0].num);
          }

          setLoad(true);
        }
         
      })
      .catch((error) => {});
  }, []);


  const tableItems = () => {
    let tableTr = [];
    if( items.length>0) {
      items.map((item,index)=>{
        if(item!=null && item!=undefined) {
          tableTr.push(
            <>
            <tr key={index}>
             <td>
             <Card.Text>{item.name}: </Card.Text>
              
             </td>
              <td>
                <div className="item">
                  {item.value}
                </div>
              </td>
             
           </tr>
          
           </>
           )  
        }
      })
    }
    return tableTr;
  }

  const editQuantity = (num) => {
    if(num+quantity<=itemQuantity && num+quantity>=1) {
      setQuantity(num+quantity);
    }
  }

  const editQuantityItem = () => {
  
    if( (quantity>0) && (quantity<=itemQuantity) ) {
        api.post(editCartUrl,{
            image_id: id,
            sub_product: product.sub_product,
            num : quantity
        })
        .then(
            (response)=> {
                if(response.data.result=="success") {
                  Swal.fire({
                    icon: '',
                    text: response.data.data,
                    showConfirmButton: false,
                    timer: 2000
                }).then(()=>{
                  window.location.href="/cart";
                })
                    
                }
                else {
                    Swal.fire({
                        icon: '',
                        text: response.data.error_description,
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            }
        )
        .catch((error)=>{})
    }
  }

  if (!load) {
    return <Loading />;
  }

  return (
    <div className="product">
      <Container fluid>
        <Row>
          <Col sm="4" md="5" lg="4">
            <div className="product-images">
                <Card>
                  <Card.Img src={defaultImage} width="100%" />
                </Card>
            </div>
          </Col>
          <Col sm="8" md="7" lg="8">
            <div className="product-details">
              <Card>
                
                <Card.Title>{product.name}</Card.Title>
                <Card.Text className="description">
                  {product.description}
                </Card.Text>

                <Card.Body>
                <table>
                  {
                    tableItems()
                  }
                </table>
                  
                  <div className="card-row">
                    <Card.Text className="title">Price: </Card.Text>
                    <div className="price">
                      <p>{product.price} SYP</p>
                    </div>
                  </div>
                  
                  {images.length > 0 ? (
                    <div className="card-row">
                      <Card.Text className="title">Quantity: </Card.Text>
                      <div className="quantity">
                       
                          <Button>
                              <div className="icon" onClick={()=>editQuantity(1)}>
                                  <img src={AddItem} height="34px" width="34px" alt="add" />
                              </div>
                              {quantity}
                              <div className="icon" onClick={()=>editQuantity(-1)}>
                                  <img src={Minus} height="34px" width="34px" alt="minus" />
                              </div>
                          </Button>   
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </Card.Body>

                <Card.Footer>
                    <div className="payment">
                      <Row>
                        <Col md="12"> 
                            <Button
                              className="add-cart"
                              onClick={editQuantityItem}
                            >
                              <img
                                src={Add}
                                height="34px"
                                width="34px"
                                alt="add-cart"
                              />
                              edit quantity
                            </Button>  
                        </Col>
                      </Row>
                    </div>
                  </Card.Footer>

              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
