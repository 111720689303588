//import Basic
import React, { useState, useEffect } from "react";

//import api
import api from "../../api/Method";

//import sweet alert
import Swal from 'sweetalert2';

//import component from react-bootstrap
import { Container, Row, Col } from "react-bootstrap";

import { Form, Button } from "react-bootstrap";

//import Css
import "./Verify.css";

//export Verify Component
export default function Verify() {
  const [code, setCode] = useState("");
  const [url, setUrl] = useState("https://dawaa-e.com/ubaha/api/verify");
  const [urlCode, setUrlCode] = useState(
    "https://dawaa-e.com/ubaha/api/resendCode"
  );

  useEffect(() => {
    //set url
    setUrl("https://dawaa-e.com/ubaha/api/verify");
    setUrlCode("https://dawaa-e.com/ubaha/api/resendCode");
  }, []);

  const submitData = (e) => {
    e.preventDefault();
    const data = {
      mobile:sessionStorage.getItem("mobile"),
      code,
    };
   
    api
      .post(url, data)
      .then((response) => {
        if (response.data.result == "success") {
          Swal.fire({
            icon: 'success',
            text: response.data.result,
            showConfirmButton: false,
            timer: 3000
          }).then(()=>{
            sessionStorage.setItem("token", response.data.data.token);
            sessionStorage.removeItem("mobile");
            window.location.href="/home";
          })
        } else {
          Swal.fire({
            icon: 'error',
            text: response.data.error_description,
            showConfirmButton: false,
            timer: 2000
          })
        }
      })
      .catch((error) => {});
  };

  const resendCode = (e) => {
    const data = {
      mobile:sessionStorage.getItem("mobile"),
    };

    api
      .post(urlCode, data)
      .then((response) => {
        if (response.data.result == "success") {
          Swal.fire({
            icon: 'success',
            text: response.data.data,
            showConfirmButton: false,
            timer: 2000
          })
        } else {
          Swal.fire({
            icon: 'error',
            text: response.data.error_description,
            showConfirmButton: false,
            timer: 2000
          })
        }
      })
      .catch((error) => {});
  };

  if (sessionStorage.getItem("token") != null) {
    window.location.href = "/home";
    return "";
  }

  return (
    <div className="verify">
      <Container fluid>
      
        <Row className="d-flex justify-content-center align-items-center">
          <Col md="7" xl="5">
            <Form onSubmit={submitData}>
              <Form.Group className="verify-header">
                <h3>Verification code</h3>
                <p>Enter the verification code that you received with SMS</p>  
              </Form.Group>

              <Form.Group>
                <Form.Control
                  type="text"
                  required
                  minLength="4"
                  maxLength="4"
                  name="code"
                  placeholder="Verification code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="form-button">
                <Button className="w-100"  type="submit">
                  Submit
                </Button>
              </Form.Group>
              <Form.Group>
                <p className="append-text d-inline-block">Don't receive on SMS with code? </p>
                <span onClick={resendCode}>
                  <p className="d-inline-block ml-1"> Resend</p>
                </span>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
