//import basic react
import React, { useState, useEffect } from 'react';

//import api
import api from '../../api/Method';


//import Home css 
import './Home.css';

//import gallery
import Gallery from '../../components/home-gallery/Gallery';

//import hot categories
import HotCategories from '../../components/hot-categories/HotCategories';

//import arrivals
import Arrivals from '../../components/new-arrivals/Arrivals';

//import loading
import Loading from '../loading/Loading';

//import components from react-bootstrap
import { Container, Row, Col } from 'react-bootstrap';

export default function Home() {
  const [categories, setCategories] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [changeGallery, setChangeGallery] = useState([]);
  const [url, setUrl] = useState("https://dawaa-e.com/ubaha/api/slider");
  const [categorySlider, setCategorySlider] = useState("https://dawaa-e.com/ubaha/api/slider_category/");
  const [id, setID] = useState(0);
  const [load, setLoad] = useState(false);

  // const [show, setShow] = useState(window.innerWidth<992?false:true);
    
  // const controlWidth = () => {
  //     if (window.innerWidth < 992) {
  //         setShow(false)
  //     } else {
  //         setShow(true)
  //     }
  // }

  // useEffect(() => {
  //     window.addEventListener('resize', controlWidth)
  //     return () => {
  //         window.removeEventListener('resize', controlWidth)
  //     }
  // }, []);

  useState(()=>{
    setUrl("https://dawaa-e.com/ubaha/api/slider");
    setCategorySlider("https://dawaa-e.com/ubaha/api/slider_category/");
  },[]);
 
  useEffect(()=>{
    //get data from database
      api.get(url)
      .then(
            (response)=> {
                if(response.data!=null && response.data.result=="success") {
                  setGallery(response.data.data);
                  setLoad(true);
                }
            }
        )
        .catch((error)=>{})
  },[]);
  
  // useEffect(()=>{
  //   //get data from database
  //     api.get(categorySlider)
  //     .then(
  //         (response)=> {
  //             if(response.data.result=="success") {
  //               setCategories(response.data.data);
  //                //get data gallery from database
  //               api.getById(categorySlider, response.data.data[id].category_id)
  //               .then(
  //                   (response)=> {
  //                       if(response.data!=null && response.data.result=="success") {      
  //                         setChangeGallery(response.data.data);
  //                         setLoad(true);
  //                       }
  //                   }
  //               )
  //               .catch((error)=>{})
  //             }
  //         }
  //     )
  //     .catch((error)=>{})
  // },[id,show]);

  const categoryItems = ()=> {
    let items = [];
    if(categories.length>0) {
      categories.map((category,index)=>{
        items.push(
          <Col  md="12" key={index}>
            <div className="category-item hvr-sweep-to-top" onClick={()=> setID(index)} style={{backgroundImage: `url(${category.image})`}}>
              <p>{category.category}</p>
            </div>
          </Col>
        );
      });
      return items;
    }
  };

   if(!load) {
    return <Loading />;
  }

  return (
    <div className="home">
      <div className="home-slider">
        
        <Gallery gallery={gallery} />
       
          <Container fluid className="home-gallery-container">
            <Row className="row-first">
              <Col md="4">
                <div className="category-items">
                  <Row>
                    {categoryItems()}
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>

        <div className="home-slider-footer"></div>
      </div>
      
     
      <div className="home-bg">
          <HotCategories />
          <Arrivals />
        </div>
    </div>
  );
}
