//import Basic
import React, { useState, useEffect } from "react";

//import api
import api from "../../api/Method";

//import sweet alert
import Swal from 'sweetalert2';

//import component from react-bootstrap
import { Container, Row, Col } from "react-bootstrap";

import { Form, Button } from "react-bootstrap";

//import Css
import "./VerifyPassword.css";

//import icon
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

//export Verify Password Component
export default function VerifyPassword() {
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [url, setUrl] = useState(
    "https://dawaa-e.com/ubaha/api/verify_forget_password"
  );

  useEffect(() => {
    //set url
    setUrl("https://dawaa-e.com/ubaha/api/verify_forget_password");
  }, []);

  const submitData = (e) => {
    e.preventDefault();
    const data = {
      mobile:sessionStorage.getItem("mobile"),
      new_password: password,
      code,
    };


    api
      .post(url, data)
      .then((response) => {
        if (response.data != null && response.data.result == "success") {
          Swal.fire({
            icon: 'success',
            text: response.data.data,
            showConfirmButton: false,
            timer: 2000
          }).then(() => {
            sessionStorage.removeItem("mobile");
            window.location.href = "/login";
          })
        } else {
          Swal.fire({
            icon: 'error',
            text: response.data.error_description,
            showConfirmButton: false,
            timer: 2000
          })
        }
      })
      .catch((error) => {});
  };

  if (sessionStorage.getItem("token") != null) {
    window.location.href = "/home";
    return "";
  }
  return (
    <div className="verify-password">
      <Container fluid>

        <Row  className="d-flex justify-content-center align-items-center">
          <Col lg="7" xl="5">
            <Form onSubmit={submitData}>
              
              <Form.Group className="verify-password-header">
                <h3>New password</h3>
                <p>Enter the verification code and your new password</p>  
              </Form.Group>

              <Form.Group>
                <Form.Control
                  type="text"
                  required
                  minLength="4"
                  maxLength="4"
                  name="code"
                  placeholder="Verification code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="form-password">
                <Form.Control
                  type={showPassword?"text":"password"}
                  required
                  minLength="8"
                  name="password"
                  placeholder="New Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                {
                  showPassword?
                  <VisibilityOffIcon className="password-icon" onClick={()=>setShowPassword(!showPassword)} />
                  :
                  <VisibilityIcon className="password-icon" onClick={()=>setShowPassword(!showPassword)} />
                }
              </Form.Group>

            

              <Form.Group className="form-button">
                <Button className="mt-2 mb-3" type="submit">
                  Confirm
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
