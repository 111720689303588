//import Basic
import React, { useState, useEffect } from "react";

//import api
import api from "../../api/Method";

//import sweet alert
import Swal from 'sweetalert2';

//import icon
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

//import component from react-bootstrap
import { Container, Row, Col } from "react-bootstrap";

import { Form, Button } from "react-bootstrap";

//import Css
import "./Profile.css";

//import image component
import Image from "./Image";

//import icon images
import Edit from '../../assets/imgs/profile/edit.png';

//import loading page
import Loading from "../loading/Loading";

//export Profile Component
export default function Profile() {
  const [url, setUrl] = useState("https://dawaa-e.com/ubaha/api/profile");
  const [editUrl, setEditUrl] = useState("https://dawaa-e.com/ubaha/api/edit_profile");
  const [logoutUrl, setLogoutUrl] = useState("https://dawaa-e.com/ubaha/api/logout_customer");
  const [changePasswordUrl, setChangePasswordUrl] = useState("https://dawaa-e.com/ubaha/api/change_password")
  const [load, setLoad] = useState(false);
  const [name, setName] = useState("");
  const [nickname, setNickname] = useState("");
  const [mobile, setMobile] = useState("");
  const [image, setImage] = useState("");
  const [updateImage, setUpdateImage] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  useEffect(() => {
    //set url
    setUrl("https://dawaa-e.com/ubaha/api/profile");
    setEditUrl("https://dawaa-e.com/ubaha/api/edit_profile");
    setLogoutUrl("https://dawaa-e.com/ubaha/api/logout_customer");
    setChangePasswordUrl("https://dawaa-e.com/ubaha/api/change_password");
  }, []);

  useEffect(() => {
    //get data from database
    api
      .get(url)
      .then((response) => {
        if (response.data != null && response.data.result == "success") {
          setName(response.data.data.name);
          setNickname(response.data.data.nickname);
          setMobile(response.data.data.mobile);
          setImage(response.data.data.image);
          setLoad(true);
        } 
      })
      .catch((error) => {});
  }, [toggle]);

  const submitData = (e) => {
    e.preventDefault();
    const data = new FormData();
    
    if (updateImage != null) {
      data.append("image", updateImage.file);
    }
    
    data.append("name", name);
    data.append("nickname", nickname);
    
    api
      .post(editUrl, data)
      .then((response) => {
        if (response.data.result == "success") {
          setToggle(!toggle);
          Swal.fire({
            icon: 'success',
            text: "Profile updated",
            showConfirmButton: false,
            timer: 2000
          }).then(() => {
            window.location.reload();
          })
        } else {
          Swal.fire({
            icon: 'error',
            showConfirmButton: false,
            text: response.data.error_description,
            timer: 2000
          })
        }
      })
      .catch((error) => {});
  };
  
  const logout = (e)=> {
    e.preventDefault();
    Swal.fire({
      text: 'Are you sure you want to log out?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Yes, sure',
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        api
        .post(logoutUrl, {})
        .then((response) => {
          if (response.data!=null && response.data.result == "success") {
            Swal.fire({
              icon: 'success',
              text: response.data.data,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              sessionStorage.removeItem("token");
              window.location.reload();
            })
          } else {
            Swal.fire({
              icon: 'error',
              showConfirmButton: false,
              text: response.data.error_description,
              timer: 2000
            })
          }
        })
        .catch((error) => {});
      } else if (result.isDenied) {
        Swal.fire({
          showConfirmButton: false,
          text: "Well, you're still signed in.",
          timer: 2000
        })
      }
    })
  }

  const changePassword = (e) => {
    e.preventDefault();
    const data = {
      mobile,
      currentPassword,
      newPassword
    };

    api
    .post(changePasswordUrl, data)
    .then((response) => {
      if (response.data!=null && response.data.result == "success") {
        Swal.fire({
          icon: 'success',
          text: response.data.data,
          showConfirmButton: false,
          timer: 2000
        }).then(() => {
          window.location.reload();
        })
      } else {
        Swal.fire({
          icon: 'error',
          showConfirmButton: false,
          text: response.data.error_description,
          timer: 2000
        })
      }
    })
    .catch((error) => {});
  }

  if (sessionStorage.getItem("token") == null) {
    window.location.href = "/login";
    return "";
  }

  if (!load) {
    return <Loading />;
  }

  return (
    <div className="profile">
      <Container fluid>
        <Row className="justify-content-center">
          <Col xl="8">
            <Form onSubmit={submitData} className="main-info">
             <h3>My profile</h3>
              <Row>
                
                <Col md="4">
                
                  <Form.Group className="profile-img">
                    <Image
                      image={image}
                      setDataImage={(value) => setUpdateImage(value)}
                    />
                  </Form.Group>
                </Col>

                <Col md="8">
                  <Form.Group className="d-lg-flex align-items-center first-name">
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      minLength="2"
                      value={name}
                      name="first-name"
                      
                      onChange={(e) => setName(e.target.value)}
                    />
                    <img src={Edit} width="" height="" alt="" />
                    </Form.Group>
                    <Form.Group className="d-lg-flex align-items-center last-name">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      minLength="2"
                      value={nickname}
                      name="last-name"
                      onChange={(e) => setNickname(e.target.value)}
                    />
                    <img src={Edit} width="" height="" alt="" />
                  </Form.Group>

                  <Form.Group className="d-lg-flex align-items-center">
                    <Form.Label>Mobile</Form.Label>
                    <Form.Control
                      disabled
                      type="text"
                      required
                      minLength="10"
                      value={mobile}
                      maxLength="10"
                      name="mobile"
                      onChange={(e) => setMobile(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="form-button d-flex justify-content-between">
                    <Button className="btn-save-changes" type="submit">
                      Save changes
                    </Button>
                    <Button onClick={logout}>
                      Log out
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          
          <Form onSubmit={changePassword} className="change-password">
            
              <Row>
              
                <Col md="8">
                <h3>Change password</h3>
                  <Form.Group className="d-lg-flex align-items-center profile-password">
                    {
                      showCurrentPassword?
                      <VisibilityOffIcon className="password-icon" onClick={()=>setShowCurrentPassword(!showCurrentPassword)} />
                      :
                      <VisibilityIcon className="password-icon" onClick={()=>setShowCurrentPassword(!showCurrentPassword)} />
                    }
                    <Form.Label>current password</Form.Label>
                    
                    <Form.Control
                      type={showCurrentPassword?"text":"password"}
                      required
                      minLength="8"
                      value={currentPassword}
                      name="current-password"
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                    
                    </Form.Group>
                     <Form.Group className="d-lg-flex align-items-center profile-password">
                     {
                      showNewPassword?
                      <VisibilityOffIcon className="password-icon" onClick={()=>setShowNewPassword(!showNewPassword)} />
                      :
                      <VisibilityIcon className="password-icon" onClick={()=>setShowNewPassword(!showNewPassword)} />
                    }
                    <Form.Label>new password</Form.Label>
                    <Form.Control
                      type={showNewPassword?"text":"password"}
                      required
                      minLength="8"
                      value={newPassword}
                      name="new-password"
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    
                  </Form.Group>

                  <Form.Group className="form-button form-button-password">
                    <Button className="ml-0" type="submit">
                      Change
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          
          </Col>
        </Row>
      </Container>
    </div>
  );
}
