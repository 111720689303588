import React, { useState } from "react";
import ImageUploading from "react-images-uploading";
import DefaultImage from "../../assets/imgs/profile/user.jpg";

export default function Image(props) {
  let [images, setImages] = useState([]);
  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
   
    setImages(imageList);
    props.setDataImage(imageList[0]);
  };

  return (
    <div className="product-images">
      <div>
        <ImageUploading
          multiple
          value={images}
          onChange={onChange}
          maxNumber={maxNumber}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            onImageUpdate,
            isDragging,
            onImageRemove,
            dragProps,
          }) => (
            // write your building UI
            <div className=" upload__image-wrapper">
              <div className="mainbtndiv">
                {imageList[0] == null ? (
                  <img
                    onClick={onImageUpload}
                    {...dragProps}
                    src={props.image != "" ? props.image : DefaultImage}
                    width="100%"
                    height="100%"
                    className="upload-image"
                  />
                ) : (
                  <img
                    {...dragProps}
                    onClick={() => onImageUpdate(0)}
                    src={imageList[0].data_url}
                    width="100%"
                    height="100%"
                    className="upload-image"
                  />
                )}
              </div>
            </div>
          )}
        </ImageUploading>
      </div>
    </div>
  );
}
