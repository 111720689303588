//import Basic
import React from "react";

//import component from react-bootstrap
import { Container, Row, Col } from "react-bootstrap";

//import Css
import "./EmptyCategory.css";

//import image
import Image from '../../assets/imgs/category/empty.png';

//export empty category Component
export default function EmptyCategory() {
 
  return (
    <div className="category-empty-page">
        <Container fluid>
            <Row>
                <Col>
                    <div className="empty-content">
                        <div className="empty-img">
                            <img src={Image} width="64px" height="64px" alt="category" />
                        </div>
                        <p className="empty-content-description">category doesn't have any products yet</p>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  );
}
