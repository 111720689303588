//import Basic
import React, { useState, useEffect } from "react";

//import api
import api from "../../api/Method";

//import sweet alert
import Swal from 'sweetalert2';

//import component from react-bootstrap
import { Container, Row, Col } from "react-bootstrap";

import { Form, Button } from "react-bootstrap";

//import Css
import "./ForgotPassword.css";

//import image
import Mobile from '../../assets/imgs/login/mobile.png';

//export Forgot Password Component
export default function ForgotPassword() {
  const [mobile, setMobile] = useState("");
  const [url, setUrl] = useState(
    "https://dawaa-e.com/ubaha/api/forget_password"
  );

  useEffect(() => {
    //set url
    setUrl("https://dawaa-e.com/ubaha/api/forget_password");
  }, []);

  const submitData = (e) => {
    e.preventDefault();
    
    const data = {
      mobile,
    };

    api
      .post(url, data)
      .then((response) => {
        if (response.data != null && response.data.result == "success") {
          Swal.fire({
            icon: 'success',
            text: response.data.data,
            showConfirmButton: false,
            timer: 3000
          }).then(() => {
            sessionStorage.setItem("mobile",mobile);      
            window.location.href = "/password-verification";
          })
        } else {
          Swal.fire({
            icon: 'error',
            text: response.data.error_description,
            showConfirmButton: false,
            timer: 2000
          })
        }
      })
      .catch((error) => {});
  };

  return (
    <div className="forgot-password">
      <Container fluid>
        <Row className="d-flex justify-content-center align-items-center">
          <Col md="7" xl="5">
            <Form onSubmit={submitData}>

              <Form.Group className="forgot-password-header">
                <h3>Reset your password</h3>
                <p>Enter your mobile number which we will send verification code to it</p>  
              </Form.Group>

              <Form.Group className="form-mobile">
                <img src={Mobile} width="36" height="36" alt="mobile-number" />
                <Form.Control
                  type="text"
                  required
                  minLength="10"
                  maxLength="10"
                  name="mobile"
                  placeholder="Mobile number"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="form-button">
                <Button type="submit">
                  Send
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
