//import basic react
import React, { useState, useEffect } from "react";

//import api
import api from "../api/Method";

import { UserContext } from "../context/User";

//import react-bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

//import gallery
import "react-image-gallery/styles/css/image-gallery.css";

//import app css
import "./App.css";

//import routes
import Routes from "../routes";

//import navbar component
import Navbar from "../components/navbar/Navbar";

import HomeNavbar from "../components/home-navbar/Navbar";

//import footer component
import Footer from "../components/footer/Footer";

//import loading page
import Loading from "../pages/loading/Loading";

//import ScrollButton
import ScrollButton from '../components/scroll/Scroll';

export default function App() {
  const [url, setUrl] = useState("https://dawaa-e.com/ubaha/api/profile");
  const [urlCart, setUrlCart] = useState("https://dawaa-e.com/ubaha/api/all_card");
  var [data, setData] = useState(null);
  const [load, setLoad] = useState(false);

  const [show, setShow] = useState(false)
    
    const controlNavbar = () => {
        if (window.scrollY < 1000) {
            setShow(false)
        } else {
            setShow(true)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', controlNavbar)
        return () => {
            window.removeEventListener('scroll', controlNavbar)
        }
    }, []);

  useEffect(() => {
    //set url
    setUrl("https://dawaa-e.com/ubaha/api/profile");
    setUrlCart("https://dawaa-e.com/ubaha/api/all_card");
  }, []);

  useEffect(() => {
    //get data from database
    api
      .get(url)
      .then((response) => {
        if (response.data != null && response.data.result == "success") {
          data = response.data.data;
          setData({...data,cart:0});    
        }
      })
      .then(()=>{
        api
          .get(urlCart)
          .then((response) => {
            if (response.data != null) {   
              setData({...data,cart:response.data.data[0].product.length});
            }
          })
          .catch((error) => {});
      })
      .then(
        setLoad(true)
      )
      .catch((error) => {});
  }, []);

  if (sessionStorage.getItem("token") != null && !load ) {
    return <Loading />;
  }

  return (
    <UserContext.Provider value={data}>
      <div className="app">
        {window.location.pathname == "/home" ||
        window.location.pathname == "/" ? (
          <HomeNavbar />
        ) : (
          <Navbar />
        )}
        <div className="content">
          <Routes />
          { window.location.pathname != "/cart" &&
            window.location.pathname != "/about-us" &&
            window.location.pathname != "/contact-us" &&
            window.location.pathname != "/login" &&
            window.location.pathname != "/register" &&
            window.location.pathname != "/forgot-password" &&
            window.location.pathname != "/password-verification" &&
            window.location.pathname != "/verification" &&
            window.location.pathname != "/my-profile"?
            (
              <Footer />
            ):""
          }
         
          {
            show?<ScrollButton scrollStepInPx="75" delayInMs="16.66"/>:""
          }
        </div>
      </div>
    </UserContext.Provider>
  );
}
