//import basic react
import React, { useState, useEffect } from 'react';

//import api
import api from '../../api/Method';

//import My Orders css 
import './MyOrders.css';

//import loading page
import Loading from '../loading/Loading';

//import components from react-bootstrap
import { Container, Row, Col, div, Button } from 'react-bootstrap';

//import empty cart component
import EmptyOrders from '../empty-orders/EmptyOrders';

export default function MyOrders() {

    const [myOrders, setMyOrders] = useState([]);
    const [url, setUrl] = useState("https://dawaa-e.com/ubaha/api/my_order");
    const [load, setLoad] = useState(false);
   
    useEffect(()=>{
        //set url
        setUrl("https://dawaa-e.com/ubaha/api/my_order");
    },[]);

    useEffect(()=>{
        //get data from database
        api.get(url)
        .then(
            (response)=> {
                if(response.data!=null && response.data.result=="success") {
                    setMyOrders(response.data.data);
                    setLoad(true);
                }
            }
        )
        .catch((error)=>{})
    },[]);

    const changeUrl = (id)=> {
        window.location.href=`my-orders/${id}`;
    }

    const myOrderItems = () => {
        let items = [];
        if(myOrders.length>0) {
            myOrders.map((item,index)=>{
            if(item!=null || item!=undefined) {
                items.push(
                    <Col lg="10" key={index}>
                        <div className="my-orders-item" onClick={()=>changeUrl(item.id)}>
                            <div className="d-flex align-items-center justify-content-between flex-wrap my-order-header">
                                <div className="order-number"><p>{`Order #${item.id}`}</p></div>
                                <div className="order-status">
                                    {item.status==0?<Button className="installed-btn">{item.status_text}</Button>:""}
                                    {item.status==1?<Button className="delivered-btn">{item.status_text}</Button>:""}
                                    {item.status==2?<Button className="canceled-btn">{item.status_text}</Button>:""}
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                <div className="order-on"><p>{`Order on: ${item.date}`}</p></div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                <div className="order-method"><p>{`Receiving method: ${item.status_type}`}</p></div>
                                <div className="order-total"><p>{`Total:  ${item.total} SYP`}</p></div>
                            </div>
                        </div>
                    </Col>
                )   
            }
            });
            return items;
        }
    }

    const MyOrdersHeader = () => {
        return(
            <div className="my-orders-header">
                <Col>
                    <h3>My Orders</h3>
                </Col>
            </div>
        );
    }

    if (sessionStorage.getItem("token") == null) {
        window.location.href = "/login";
        return "";
      }

    if(!load) {
        return <Loading />;
    }
    if(myOrders.length<=0) {
        return <EmptyOrders />
    }
    return (
        <div className="my-orders">
            <Container fluid>
                <Row>
                    {MyOrdersHeader()}
                </Row>
                <Row>
                    {myOrderItems()}
                </Row>
            </Container>
        </div>
    );
}
