import React, { useState, useEffect } from "react";

import api from '../../api/Method';

import { Modal, Button, Form } from "react-bootstrap";

//import sweet alert
import Swal from 'sweetalert2';

import "./Modal.css";

export default function CheckoutModal(props) {
  const [address, setAddress] = useState(null);
  const [mobile, setMobile] = useState("");
  const [method, setMethod] = useState("");
  const [show, setShow] = useState(false);
  const [url, setUrl] = useState("https://dawaa-e.com/ubaha/api/submit_order");

  useEffect(()=>{
      //set url
      setUrl("https://dawaa-e.com/ubaha/api/submit_order");
  },[]);

  const submitData = (e) => {
    e.preventDefault();
    const data = {
        order_id: props.orderID,
        address,
        mobile
    }
    api.post(url, data).then((response) => {
        if (response.data != null && response.data.result == "success") {
          Swal.fire({
            icon: '',
            text: response.data.data,
            showConfirmButton: false,
            timer: 2000
          }).then(() => {
            window.location.href = "/my-orders";
          })
        } else {
          Swal.fire({
            icon: '',
            text: response.data.error_description,
            showConfirmButton: false,
            timer: 2000
          })
        }
      })
      .catch((error) => {});
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title"
      centered
      className="modal"
    >
      <Form onSubmit={submitData}>
        <Modal.Body>
          <h3 id="modal-title">Checkout</h3>
          <p id="modal-paragraph-total">You are about to submit an order for 90,000 SYP</p>
          <p id="modal-paragraph-method">Choose how you want to receive the order:</p>

          <Form.Group className="modal-choice-method">
             <input
              type="radio"
              required
              id="backup"
              name="checkout-method"
              defaultChecked
              onChange={() => {setMethod("backup");setShow(false)}}
            />
            <Form.Label for="backup" id="backup-label">Pick up</Form.Label>

            <input
              type="radio"
              required
              id="delivery"
              name="checkout-method"
              onChange={() => {setMethod("delivery");setShow(true)}}
            />
            <Form.Label for="delivery" id="delivery-label">Delivery</Form.Label>

          </Form.Group>

            <Form.Group className="d-flex align-items-center mobile-checkout">
              <Form.Label>Mobile:</Form.Label>
              <Form.Control
                type="text"
                required
                minLength="10"
                name="mobile"
                placeholder="Enter your mobile number"
                onChange={(e) => setMobile(e.target.value)}
               
              />
              </Form.Group>
              
              {
                show?
                <Form.Group className="d-flex align-items-center address-checkout">
                  <Form.Label>Address:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your address "
                    minLength="2"
                    name="address"
                    disabled={method=="delivery"?false:true}
                    required={method=="delivery"?true:false}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Form.Group> 
                :""
              }

          <div className="modal-btn">
            <Button className="modal-btn-submit" type="submit">Submit</Button>
            <Button className="modal-btn-cancel" onClick={props.onHide}>Cancel</Button>
          </div>
        </Modal.Body>
      </Form>
    </Modal>
  );
}
